import { WindowMaybeWithCardano } from "@cardano-sdk/cip30";

import { WalletAndName } from "../types";

const SUPPORTED_WALLET_NAMES = ["eternl", "nami"];

export function listWallets(): WalletAndName[] {
  const cardano = (window as unknown as WindowMaybeWithCardano).cardano;
  if (!cardano) return [];
  return Object.entries(cardano)
    .filter(([name, _]) => SUPPORTED_WALLET_NAMES.includes(name))
    .map(([name, wallet]) => {
      return { name, wallet };
    });
}
