import {
  Constr,
  Data,
  Assets,
  SpendingValidator,
  TxHash,
  Script,
} from "lucid-cardano";
import { Buffer } from "buffer";
import { WalletApi } from "@cardano-sdk/cip30";
import { sha256 } from "js-sha256";

import { Raffle, Ticket } from "../types";
import { getLucid, getTicketValidator, toEnvelope } from "./common";
import { fetchTickets } from "../apiRequests/raffles";
import { getEnvVars } from "../constants";

const { fluffPolicyId, fluffTokenName } = getEnvVars();

function getFluffInBlockfrostValue(
  bv: Array<{ unit: string; quantity: string }>
): number {
  for (const { unit, quantity } of bv) {
    if (unit === `${fluffPolicyId}${fluffTokenName}`) {
      return parseInt(quantity);
    }
  }
  return 0;
}

function getLovelaceInBlockfrostValue(
  bv: Array<{ unit: string; quantity: string }>
): number {
  for (const { unit, quantity } of bv) {
    if (unit === "lovelace") {
      return parseInt(quantity);
    }
  }
  return 0;
}

function pseudoRandomIntFromSeed(seed: string, limit: number): number {
  const seedHashed = sha256(seed);
  const part = seedHashed.substring(5, 11); // take six hex digits from that seed hash
  const num = parseInt(part, 16);
  return num % limit;
}

// filter(
//   (ticket) =>
//     parseInt(getFluffInBlockfrostValue(ticket.amount)) >=
//     ticket_price_fluff
export async function pickWinningTicket(
  rawTickets: Array<Ticket>,
  ticket_price_fluff: number,
  ticket_price_lovelace: number,
  is_ada_only: boolean
): Promise<Ticket> {
  // Artificially duplicate tickets based on their fluff content
  // i.e. a utxo containing enough fluff for 4 tickets gets copied 4 times
  // to improve the win probability
  const tickets = rawTickets
    .map((ticket) => {
      const ticketsBought = is_ada_only
        ? getLovelaceInBlockfrostValue(ticket.amount) / ticket_price_lovelace
        : getFluffInBlockfrostValue(ticket.amount) / ticket_price_fluff;
      const artificialTickets = [];
      for (let i = 0; i < ticketsBought; i++) {
        artificialTickets.push(ticket);
      }
      return artificialTickets;
    })
    .flat(1);
  console.log({ tickets });
  if (!tickets.length)
    throw new Error(
      "No tickets have been bought or the raffle has already been ended"
    );

  const txoutrefs = tickets.map(
    (ticket) => ticket.tx_hash + "#" + ticket.tx_index
  );
  txoutrefs.sort();
  const winningIndex = pseudoRandomIntFromSeed(
    txoutrefs.join(","),
    tickets.length
  );
  const winningTicket = tickets[winningIndex];
  console.log("winning ticket", winningTicket);
  return winningTicket;
}

export async function pickWinnerProcess(
  raffle: Raffle,
  cardanoApi: WalletApi
): Promise<TxHash> {
  console.log("pickWinner", raffle);
  const lucid = await getLucid(cardanoApi);

  const ourAddress = await lucid.wallet.address();
  console.log("ourAddress", ourAddress);

  const ticketValidator: Script = {
    type: "PlutusV2",
    script: raffle.buy_ticket_cbor_hex,
  };
  const winningTicket = await pickWinningTicket(
    await fetchTickets(raffle.buy_ticket_address),
    raffle.ticket_price_fluff,
    raffle.ticket_price_lovelace,
    raffle.is_ada_only
  );
  const winningAddress = winningTicket.return_address;
  console.log("winningAddress", winningAddress);
  const winningUtxos = await lucid.utxosByOutRef([
    {
      txHash: winningTicket.tx_hash,
      outputIndex: winningTicket.tx_index,
    },
  ]);
  console.log("winningUtxos", winningUtxos);
  // NOTE: This corresponds to 'Win' in /validators/src/TicketValidator.hs
  const ticketRedeemer = Data.to(new Constr(0, []));

  const raffleScript: SpendingValidator = {
    type: "PlutusV2",
    script: raffle.raffle_script_cbor_hex,
  };
  const raffleScriptAddress = lucid.utils.validatorToAddress(raffleScript);
  console.log({ raffleScriptAddress });

  // NOTE: This corresponds to 'PickWinner' in /validators/src/RaffleValidator.hs
  const raffleRedeemer = Data.to(new Constr(0, []));
  console.log({ raffleRedeemer });

  // Querying raffle UTxOs from blockfrost
  const raffleUtxos = await lucid.utxosAt(raffleScriptAddress);

  // TODO: Use tokens instead of stored tx_out_ref to find the raffle UTxO
  const [rtxid, rtxix] = raffle.tx_out_ref.split("#");
  const raffleUtxo = raffleUtxos.find(
    (utxo) => utxo.txHash === rtxid && utxo.outputIndex === parseInt(rtxix)
  );
  if (raffleUtxo === undefined) {
    throw new Error(`could not find raffle utxo using ${rtxid} ${rtxix}`);
  }
  console.log({ raffleUtxo });

  const newRaffleDatum = Data.to(
    new Constr(0, [
      new Constr(1, []),
      new Buffer(raffle.ipfs_file_hash, "ascii"),
    ])
  );
  // TODO: PLACEHOLDER should contain the remaining value (at least the thread token + some ADA)
  const newRaffleValue: Assets = { lovelace: BigInt(2000000) };

  // Currency symbol is concatenation of policy id and token name. token_name is empty string.
  // This was done as we get it concatenated from blockfrost, no need to split
  const { currency_symbol } = raffle.prize;
  const wonAssets: Assets = {
    lovelace: BigInt(raffle.prize.amount_of_lovelace),
    [currency_symbol]: BigInt(1),
  };
  console.log({ wonAssets });

  // FIXME: Spending the ticket here makes the displays indicate only 4 tickets
  // were sold. Need to do +1 when a raffle ended / a winner got picked.
  const tx = await lucid
    .newTx()
    .collectFrom(winningUtxos, ticketRedeemer)
    .attachSpendingValidator(ticketValidator)
    .collectFrom([raffleUtxo], raffleRedeemer)
    .attachSpendingValidator(raffleScript)
    .payToContract(
      raffleScriptAddress,
      { inline: newRaffleDatum },
      newRaffleValue
    )
    .payToAddress(winningAddress, wonAssets)
    .addSigner(ourAddress)
    .complete();
  console.log("tx", toEnvelope(tx));

  const signedTx = await tx.sign().complete();
  console.log("signedTx", toEnvelope(signedTx));

  const txid = await signedTx.submit();
  console.log({ txid });
  return txid;
}
