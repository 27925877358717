import { Icon, Stack, Typography } from "@mui/material";

import { Raffle } from "../../types";

interface RaffleCardProps {
  raffle: Raffle;
  size?: "s" | "m";
}

export const FloorPrice = ({ raffle, size = "m" }: RaffleCardProps) => {
  const fontSize = size === "m" ? 14 : 12;
  const iconFontSize = size === "m" ? 20 : 16;

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Icon style={{ fontSize: iconFontSize }}>payments</Icon>
      <Typography fontSize={fontSize}>
        Floor: {raffle.floor_price_lovelace / 1000000} ADA
      </Typography>
    </Stack>
  );
};
