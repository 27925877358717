import { CreateRaffle, Raffle } from "../types";

import { Buffer } from "buffer";
import { WalletApi } from "@cardano-sdk/cip30";
import { createRaffle, getRaffleIdFromUTxO } from "../apiRequests/raffles";
import { AxiosError } from "axios";
import { Assets, Constr, Data, Lucid, SpendingValidator } from "lucid-cardano";
import {
  getLucid,
  toEnvelope,
  getTicketValidator,
  getBuyTicketValidatorHex,
} from "./common";
import { Asset } from "../apiRequests/assets";
import { M_RAFFLE_CBOR_HEX, RAFFLE_SCRIPT_CBOR_HEX } from "../constants";

interface CreateRaffleProcessResponse {
  raffle: Raffle;
  txid: string;
}

async function createRaffleTransaction(
  cardanoApi: WalletApi,
  asset: Asset,
  raffle: Raffle
): Promise<CreateRaffleProcessResponse> {
  const lucid = await getLucid(cardanoApi);

  console.log({ raffle });
  console.log(raffle.raffle_script_cbor_hex);
  const raffleScript: SpendingValidator = {
    type: "PlutusV2",
    script: raffle.raffle_script_cbor_hex,
  };
  const raffleScriptAddress = lucid.utils.validatorToAddress(raffleScript);
  console.log({ raffleScriptAddress });

  const raffleDatum = Data.to(
    new Constr(0, [
      new Constr(0, []), // Active
      new Buffer(raffle.ipfs_file_hash, "ascii"),
    ])
  );
  const raffleValue: Assets = { [asset.asset]: BigInt(1) };
  console.log(raffleValue);
  let tx = await lucid
    .newTx()
    .collectFrom([asset.utxo])
    .payToContract(raffleScriptAddress, { inline: raffleDatum }, raffleValue)
    .complete();

  const signedTx = await tx.sign().complete();
  console.log("signedTx", toEnvelope(signedTx));

  const txid = await signedTx.submit();
  console.log({ txid });

  return { raffle, txid };
}

export async function createRaffleProcess(
  cardanoApi: WalletApi,
  raffle: CreateRaffle,
  asset: Asset,
  log: null | ((logMessage: string) => void)
): Promise<CreateRaffleProcessResponse> {
  if (!log) log = (msg) => console.log(msg);
  try {
    log("validating");
    // prize

    if (!raffle.is_ada_only && raffle.ticket_price_fluff <= 0) {
      log("Fluff price cannot be zero.");
      throw new Error("Fluff price cannot be zero.");
    }

    log("finding the UTxO containing the prize");

    const utxoString = `${asset.utxo.txHash}#${asset.utxo.outputIndex}`;
    console.log({ utxoString });

    log("got prize UTxO: " + utxoString);
    raffle.utxo_containing_prize = utxoString;
    const lucid = await getLucid(cardanoApi);

    log("creating raffle in backend; IPFS");
    // TODO get the raffle id in the frontend from the utxo used to create the raffle
    // This is a hack to use the backend to get the raffle id
    const intermediateRaffle = await getRaffleFromCreateRaffle(raffle, lucid);
    console.log({ intermediateRaffle });
    log("calculated scripts locally");

    const createdRaffle = await createRaffle(intermediateRaffle);
    log("created raffle in backend; IPFS");

    log("creating raffle transaction");
    const res = await createRaffleTransaction(cardanoApi, asset, createdRaffle);

    log("done");

    return res;
  } catch (e) {
    if (e instanceof AxiosError) {
      log(`ERROR: ${e?.code} ${JSON.stringify(e?.response?.data)}`);
    } else {
      log(`ERROR: ${e}`);
    }
    throw e;
  }
}
async function getRaffleFromCreateRaffle(
  raffle: CreateRaffle,
  lucid: Lucid
): Promise<Raffle> {
  // const raffle_minting_policy = getRaffleIdFromUTxO(
  //   raffle.utxo_containing_prize
  // );
  // const id = lucid.utils.mintingPolicyToId(raffle_minting_policy);
  const id = await getRaffleIdFromUTxO(raffle.utxo_containing_prize);
  console.log({ id });
  const ticketValidator = getTicketValidator(id);
  const buy_ticket_address = lucid.utils.validatorToAddress(ticketValidator);
  const buy_ticket_cbor_hex = getBuyTicketValidatorHex(id);
  const raffleScript: SpendingValidator = {
    type: "PlutusV2",
    script: RAFFLE_SCRIPT_CBOR_HEX,
  };
  const raffle_script_address = lucid.utils.validatorToAddress(raffleScript);
  return {
    ...raffle,
    id,
    raffle_script_address,
    raffle_script_cbor_hex: RAFFLE_SCRIPT_CBOR_HEX,
    // This is unused and constant. Does not depend on the raffle script
    // TODO remove this from backend and frontend
    raffle_minting_policy_cbor_hex: M_RAFFLE_CBOR_HEX,
    tx_out_ref: "",
    buy_ticket_cbor_hex,
    buy_ticket_address,
    number_of_tickets_sold: 0,
    is_active: true,
    ipfs_file_hash: "",
    raffle_ended: false,
    tickets_sold: [],
    winning_address: null,
  };
}
