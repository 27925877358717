import { Icon, Stack, Typography } from "@mui/material";

import { Raffle } from "../../types";

interface RaffleCardProps {
  raffle: Raffle;
  size?: "s" | "m";
  walletAddress: string;
}

export const TicketsSold = ({
  raffle,
  size = "m",
  walletAddress,
}: RaffleCardProps) => {
  const fontSize = size === "m" ? 14 : 12;
  const iconFontSize = size === "m" ? 20 : 16;
  const myTickets = raffle.tickets_sold
    .filter((ticket) => ticket.return_address === walletAddress)
    .map((ticket) => ticket.number_of_tickets)
    .reduce((partialSum, a) => partialSum + a, 0);

  const ticketText =
    size === "m"
      ? `My tickets | Total : ${myTickets} | ${raffle.number_of_tickets_sold}`
      : `Total tickets: ${raffle.number_of_tickets_sold}`;
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography fontSize={fontSize}>{ticketText}</Typography>
      <Icon style={{ fontSize: iconFontSize }}>confirmation_number</Icon>
    </Stack>
  );
};
