import { CreateRaffle, Raffle, Ticket } from "../types";

import axios from "axios";
import { Address } from "lucid-cardano";
import { getEnvVars } from "../constants";
export const BASE_URL = getEnvVars().backendBaseURL;
// TODO make this function do pagination
export async function fetchActiveRaffles(): Promise<Raffle[]> {
  const paginatedRaffles = await axios.get(`${BASE_URL}/raffles`);
  return paginatedRaffles.data.items
    .filter((raffle: Raffle) => {
      return new Date(raffle.start_date) < new Date();
    })
    .map((raffle: Raffle) => {
      return {
        ...raffle,
        raffle_ended: new Date(raffle.end_date) < new Date(),
      };
    });
}
export async function fetchAllRaffles(): Promise<Raffle[]> {
  const paginatedRaffles = await axios.get(getEnvVars().s3BucketURL, {
    headers: {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
    },
  });

  return paginatedRaffles.data.items.map((raffle: Raffle) => {
    return {
      ...raffle,
      raffle_ended: new Date(raffle.end_date) < new Date(),
    };
  });
}

export async function getRaffleIdFromUTxO(utxo: string): Promise<string> {
  const response = await axios.post(`${BASE_URL}/raffle_id`, { utxo: utxo });
  return response.data.id;
}

export async function createRaffle(raffle: Raffle): Promise<Raffle> {
  const createdRaffle = await axios.post(`${BASE_URL}/raffles`, raffle);
  return createdRaffle.data;
}

export async function fetchTickets(ticketAddress: Address): Promise<Ticket[]> {
  console.log("fetchTickets", ticketAddress);
  const response = await axios.get(
    `${BASE_URL}/utxos/${ticketAddress.toString()}`
  );
  const tickets = response.data;
  console.log(tickets);
  return tickets as Ticket[];
}
