import {
  CircularProgress,
  Grid,
  LinearProgress,
  Modal,
  Stack,
} from "@mui/material";
import { useEffect, useState } from "react";

import { FilterBar, filterRaffles } from "../components/FilterBar";
import { Raffle } from "../types";
import { RaffleCard } from "../components/RaffleCard";
import { RaffleDetailView } from "../components/RaffleDetailView";
import { WalletApi } from "@cardano-sdk/cip30";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ORGANIZER_PATH } from "../constants";

type ListRafflesProps = {
  raffles: Raffle[];
  api?: WalletApi;
  setConnectWalletModalOpen: () => void;
  organizerMode: boolean;
  fluffInWallet: bigint;
  lovelaceInWallet: bigint;
  walletAddress: string;
};

export function ListRaffles({
  raffles,
  api,
  setConnectWalletModalOpen,
  organizerMode,
  fluffInWallet,
  lovelaceInWallet,
  walletAddress,
}: ListRafflesProps) {
  let { id } = useParams();
  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedRaffle, setSelectedRaffle] = useState<Raffle | undefined>();
  const [filteredRaffles, setFilteredRaffles] = useState<Raffle[]>([]);
  const rafflesShown: Raffle[] = organizerMode
    ? raffles
    : raffles.filter((raffle: Raffle) => {
        return new Date(raffle.start_date) < new Date();
      });
  useEffect(() => {
    setFilteredRaffles(
      filterRaffles(rafflesShown, "Active", "All", "", "Ending soon", "")
    );
    const linkedRaffle = rafflesShown.find((raffle) => raffle.id === id);
    if (linkedRaffle) {
      setSelectedRaffle(linkedRaffle);
      setModalOpen(true);
    }
  }, [raffles]);

  return (
    <Stack>
      <FilterBar
        raffles={rafflesShown}
        setFilteredRaffles={setFilteredRaffles}
        walletAddress={walletAddress}
        initialStatus={"Active"}
      />
      <Grid container spacing={3} justifyContent="start" alignItems="center">
        <Stack sx={{ height: 80 }}></Stack>
        {filteredRaffles.map((raffle, index) => (
          <Grid key={index} item>
            <Stack>
              <Link to={raffle.id} replace={true}>
                <RaffleCard
                  onCardClick={() => {
                    setSelectedRaffle(raffle);
                    setModalOpen(true);
                  }}
                  raffle={raffle}
                  walletAddress={walletAddress}
                />
              </Link>
            </Stack>
          </Grid>
        ))}
      </Grid>

      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
          navigate(organizerMode ? `/${ORGANIZER_PATH}` : "/");
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          margin: "auto",
          alignSelf: "center",
          justifyContent: "center",
          backgroundColor: "rgba(0, 0, 0, 0.6)",
        }}
      >
        <RaffleDetailView
          api={api}
          raffle={selectedRaffle}
          setConnectWalletModalOpen={setConnectWalletModalOpen}
          organizerMode={organizerMode}
          lovelaceInWallet={lovelaceInWallet}
          fluffInWallet={fluffInWallet}
          walletAddress={walletAddress}
        />
      </Modal>
    </Stack>
  );
}
