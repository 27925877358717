import { WalletApi } from "@cardano-sdk/cip30";
import { UTxO } from "lucid-cardano";
import { getEnvVars } from "../constants";
import { getLucid } from "../transactions/common";

const { blockfrostBaseURL, blockfrostAPIKey } = getEnvVars();

async function blockfrostAssetById(asset: string, utxo: UTxO): Promise<Asset> {
  const response = await fetch(`${blockfrostBaseURL}/api/v0/assets/${asset}`, {
    headers: { project_id: blockfrostAPIKey },
  }).then((x) => x.json());
  if (response && !response.error) return { ...response, utxo };
  else throw new Error(`cannot fetch asset ${asset}: ${response.error}`);
}
(window as any).blockfrostAssetById = blockfrostAssetById;

export interface Asset {
  utxo: UTxO;
  asset: string;
  policy_id: string;
  fingerprint: string;
  quantity: string | number;
  onchain_metadata: {
    name: string;
    image: string;
  };
  metadata: Object;
}

/*
  i.e. for tednation TED #3252:
  {
  "asset": "9547b7d827ffd46cec3b4cfb5c6321bdf91564a5cb9205339bac4e7554656433323532",
  "policy_id": "9547b7d827ffd46cec3b4cfb5c6321bdf91564a5cb9205339bac4e75",
  "asset_name": "54656433323532",
  "fingerprint": "asset1q8elct2e2w33rjypafeekx95e53ek8msj72d9a",
  "quantity": "1",
  "initial_mint_tx_hash": "33008aeaa2dbb6be94e257d686c8f98deb68538348c0f41beda026d6e21c963e",
  "mint_or_burn_count": 1,
  "onchain_metadata": {
    "name": "Ted #3252",
    "image": "ipfs://QmeHoa1xANF24C4ME2FkZAMtAorgNPMRUZMN2SBQC9qZGs",
    "Eyes": "Normal",
    "Role": "Alchemist",
    "Skin": "Normal",
    "Mouth": "Aaaaah",
    "files": [
      {
        "src": "ipfs://QmeHoa1xANF24C4ME2FkZAMtAorgNPMRUZMN2SBQC9qZGs",
        "name": "Ted #3252",
        "mediaType": "image/png"
      }
    ],
    "BabyTed": "None",
    "Website": "https://tednation.com/",
    "Clothing": "Naked",
    "Spacesuit": "None",
    "mediaType": "image/png",
    "Background": "Light Green",
    "Eye Accessory": "None",
    "Head Accessory": "Pika"
  },
  "metadata": null
}
*/

export async function listAllAssetsInWallet(
  cardanoApi: WalletApi
): Promise<Asset[]> {
  const lucid = await getLucid(cardanoApi);
  const utxos = await lucid.wallet.getUtxos();
  const assets = await Promise.all(
    utxos
      .map((utxo) =>
        Object.keys(utxo.assets)
          .filter((asset) => asset !== "lovelace")
          .map((asset) => blockfrostAssetById(asset, utxo))
      )
      .flat(1)
  );
  return assets;
}

export async function getAssetInWallet(
  cardanoApi: WalletApi,
  assetName: string
): Promise<bigint> {
  const lucid = await getLucid(cardanoApi);
  const utxos: UTxO[] = await lucid.wallet.getUtxos();
  return utxos.reduce((total, utxo) => {
    const quantity: bigint | undefined = utxo.assets[assetName];
    if (quantity) return total + quantity;
    return total;
  }, BigInt(0));
}

export async function getWalletAddress(cardanoApi: WalletApi): Promise<string> {
  const lucid = await getLucid(cardanoApi);
  return await lucid.wallet.address();
}
