import { Button, Stack } from "@mui/material";

import Logo from "../logo.svg";

export const Footer = () => {
  return (
    <Stack
      style={{
        height: 200,
        display: "flex",
        flexDirection: "row",
        padding: 10,
      }}
    ></Stack>
  );
};
