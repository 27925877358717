import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Icon,
  Stack,
  Typography,
} from "@mui/material";

import { EndDate } from "./RaffleCardInfo/EndDate";
import { Price } from "./RaffleCardInfo/Price";
import { Raffle } from "../types";
import { TicketsSold } from "./RaffleCardInfo/TicketsSold";
import { FloorPrice } from "./RaffleCardInfo/FloorPrice";

interface RaffleCardProps {
  raffle: Raffle;
  onCardClick: (raffle: Raffle) => any;
  walletAddress: string;
}

export const RaffleCard = ({
  raffle,
  onCardClick,
  walletAddress,
}: RaffleCardProps) => {
  const cardContentStyle: Record<string, string | number> = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    padding: 16,
    flexDirection: "column",
  };
  return (
    <Card
      onClick={() => onCardClick(raffle)}
      raised
      sx={{ borderRadius: 3, width: 300, margin: 1 }}
      style={{ position: "relative" }}
    >
      <CardMedia
        component="img"
        alt={raffle.prize.image_url}
        height="400"
        image={raffle.prize.image_url}
        style={{ marginBottom: 0 }}
      />
      <CardContent className="hoverable-card" style={cardContentStyle}>
        <Stack
          justifyContent="space-between"
          direction="column"
          sx={{ height: "90%" }}
        >
          <Stack>
            <Typography fontSize={16} gutterBottom>
              {raffle.collection_name} · {raffle.category}
            </Typography>
            <Typography fontSize={24} gutterBottom>
              {raffle.title}
            </Typography>
          </Stack>
        </Stack>
        <Stack spacing={1} sx={{ marginLeft: 1, marginRight: 1 }}>
          <Stack direction="row" justifyContent="space-between">
            <Price raffle={raffle} size="s" />
            <TicketsSold
              raffle={raffle}
              size="s"
              walletAddress={walletAddress}
            />
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <FloorPrice raffle={raffle} size="s" />
            <EndDate raffle={raffle} size="s" />
          </Stack>
        </Stack>
        <CardActions>
          <Button
            fullWidth
            size="small"
            variant={raffle.is_active ? "contained" : undefined}
            disabled={raffle.is_active && raffle.raffle_ended}
          >
            {raffle.is_active ? "Buy ticket" : "Show winner"}
          </Button>
        </CardActions>
      </CardContent>
    </Card>
  );
};
