import * as React from "react";
import { CreateRaffle } from "../types";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
} from "@mui/material";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { getInitialRaffleMeta } from "../constants";
import Modal from "@mui/material/Modal";
import { MyDateTimePicker } from "./MyDateTimePicker";
import Typography from "@mui/material/Typography";
import { WalletApi } from "@cardano-sdk/cip30";
import { createRaffleProcess } from "../transactions/createRaffleProcess";
import { Asset } from "../apiRequests/assets";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
type CreateRaffleFormProps = {
  cardanoApi: WalletApi;
  setRaffleMetaHash: (hash: string) => void;
  setTxid: (txid: string) => void;
  asset: Asset;
};

function nowForLog() {
  return new Date().toLocaleTimeString();
}

export const CreateRaffleForm = ({
  cardanoApi,
  setRaffleMetaHash,
  setTxid,
  asset,
}: CreateRaffleFormProps) => {
  const [open, setOpen] = React.useState(false);
  const [raffleMeta, setRaffleMeta] = React.useState<CreateRaffle>(
    getInitialRaffleMeta(asset)
  );
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const create = async () => {
    const { raffle, txid } = await createRaffleProcess(
      cardanoApi,
      raffleMeta,
      asset,
      appendLogMessage
    );
    setRaffleMetaHash(raffle.ipfs_file_hash);
    setOpen(false);
    setTxid(txid);
  };
  const setterFactory = (
    fieldName: string
  ): ((event: React.ChangeEvent<HTMLInputElement>) => void) => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      setRaffleMeta({ ...raffleMeta, [fieldName]: event.target.value });
    };
  };
  const prizeSetterFactory = (prizeField: string) => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      setRaffleMeta({
        ...raffleMeta,
        prize: { ...raffleMeta.prize, [prizeField]: event.target.value },
      });
    };
  };
  const socialsSetterFactory = (social: string) => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      setRaffleMeta({
        ...raffleMeta,
        socials: { ...raffleMeta.socials, [social]: event.target.value },
      });
    };
  };
  const [logMessages, setLogMessages] = React.useState<string[]>([]);
  function appendLogMessage(message: string) {
    setLogMessages((logMessages) =>
      logMessages.concat([nowForLog() + ": " + message])
    );
  }

  return (
    <div>
      <Button onClick={handleOpen}>Create Raffle</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack direction="row" spacing={1}>
            <Typography></Typography>
            <Stack spacing={1}>
              <TextField
                label="Title"
                value={raffleMeta.title}
                onChange={setterFactory("title")}
                multiline
                maxRows={3}
              />
              <TextField
                label="Description"
                value={raffleMeta.description}
                onChange={setterFactory("description")}
                multiline
                maxRows={3}
              />
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={raffleMeta.is_ada_only}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ): void => {
                        setRaffleMeta({
                          ...raffleMeta,
                          is_ada_only: event.target.checked,
                        });
                      }}
                    />
                  }
                  label="Ada only raffle"
                />
              </FormGroup>
              <TextField
                label="Category"
                value={raffleMeta.category}
                onChange={setterFactory("category")}
              />
              <TextField
                label="Ticket price ($fluff)"
                value={raffleMeta.ticket_price_fluff}
                onChange={setterFactory("ticket_price_fluff")}
                type="number"
              />
              <TextField
                label="Ticket price ($lovelace)"
                value={raffleMeta.ticket_price_lovelace}
                onChange={setterFactory("ticket_price_lovelace")}
                type="number"
                helperText="Must be greater than 1.5m"
                error={raffleMeta.ticket_price_lovelace < 1500000}
              />
            </Stack>
            <Stack spacing={1}>
              <Typography>Social</Typography>
              <TextField
                label="Discord"
                value={raffleMeta.socials.discord}
                onChange={socialsSetterFactory("discord")}
                type="url"
              />
              <TextField
                label="Twitter"
                value={raffleMeta.socials.twitter}
                onChange={socialsSetterFactory("twitter")}
                type="url"
              />
              <TextField
                label="Jpg.store"
                value={raffleMeta.socials.jpgstore}
                onChange={socialsSetterFactory("jpgstore")}
                type="url"
              />
              <TextField
                label="Website"
                value={raffleMeta.socials.website}
                onChange={socialsSetterFactory("website")}
                type="url"
              />
            </Stack>
            <Stack spacing={1}>
              <Typography>Prize</Typography>
              <TextField
                label="Image URL"
                value={raffleMeta.prize.image_url}
                onChange={prizeSetterFactory("image_url")}
                type="url"
              />
              <TextField
                label="Collection name"
                value={raffleMeta.collection_name}
                onChange={setterFactory("collection_name")}
              />
              <TextField
                label="Floor price ($lovelace)"
                value={raffleMeta.floor_price_lovelace}
                onChange={setterFactory("floor_price_lovelace")}
                type="number"
              />
              <TextField
                label="Amount of $lovelace"
                value={raffleMeta.prize.amount_of_lovelace}
                type="number"
              />
              <TextField
                label="Currency symbol"
                value={raffleMeta.prize.currency_symbol}
              />
              <TextField
                label="Token name"
                value={raffleMeta.prize.token_name}
              />
            </Stack>
            <Stack spacing={1}>
              <Typography>Miscellaneous</Typography>
              <TextField
                label="Popularity"
                value={raffleMeta.popularity}
                onChange={setterFactory("popularity")}
                type="number"
              />
              <MyDateTimePicker
                label="Start date"
                dateTime={raffleMeta.start_date}
                setDateTime={(dateTime) => {
                  if (!dateTime) return;
                  setRaffleMeta({
                    ...raffleMeta,
                    start_date: dateTime?.toString(),
                  });
                }}
              />
              <MyDateTimePicker
                label="End date"
                dateTime={raffleMeta.end_date}
                setDateTime={(dateTime) => {
                  if (!dateTime) return;
                  setRaffleMeta({
                    ...raffleMeta,
                    end_date: dateTime?.toString(),
                  });
                }}
              />
            </Stack>
          </Stack>
          <Stack direction="row-reverse">
            <Button onClick={create}>Create Raffle</Button>
          </Stack>
          <div>
            {logMessages.length > 0 ? <p>Log Outputs:</p> : null}
            {logMessages.map((m) => (
              <div>{m}</div>
            ))}
          </div>
        </Box>
      </Modal>
    </div>
  );
};
