import {
  BigNum,
  TransactionUnspentOutput,
  Value,
} from "@emurgo/cardano-serialization-lib-asmjs";
import {
  Blockfrost,
  SpendingValidator,
  Lucid,
  Constr,
  applyParamsToScript,
  fromHex,
  TxComplete,
  TxSigned,
  Script,
} from "lucid-cardano";
import { WalletApi } from "@cardano-sdk/cip30";

import { Raffle } from "../types";
import {
  getEnvVars,
  M_RAFFLE_CBOR_HEX,
  V_TICKET_UNAPPLIED_HEX,
} from "../constants";

let globalLucid = null as Lucid | null;
const { blockfrostBaseURL, blockfrostAPIKey, mainnet } = getEnvVars();

export async function getLucid(cardanoApi: WalletApi) {
  if (globalLucid === null) {
    globalLucid = await Lucid.new(
      new Blockfrost(`${blockfrostBaseURL}/api/v0`, blockfrostAPIKey),
      mainnet ? "Mainnet" : "Preview"
    );
    globalLucid.selectWallet(cardanoApi as any);
  }
  return globalLucid;
}
export async function setLucid(cardanoApi: WalletApi) {
  globalLucid = await Lucid.new(
    new Blockfrost(`${blockfrostBaseURL}/api/v0`, blockfrostAPIKey),
    mainnet ? "Mainnet" : "Preview"
  );
  globalLucid.selectWallet(cardanoApi as any);
}

export async function fetchUtxos(
  cardanoApi: WalletApi,
  amountOfLovelace: number
): Promise<TransactionUnspentOutput[]> {
  const askingFor = Value.new(BigNum.from_str(amountOfLovelace.toString()));
  console.log(askingFor.to_js_value());
  let hexInputUtxos = await cardanoApi.getUtxos(askingFor.to_hex());
  if (!hexInputUtxos) {
    throw new Error("not enough funds");
  }
  return hexInputUtxos.map((u) => TransactionUnspentOutput.from_hex(u));
}

export function getTicketValidator(raffleId: string): SpendingValidator {
  console.log("getTicketValidator", raffleId);

  // The v_ticket validator with RaffleId not applied.
  // See validators/README.md how to obtain this from the validators package.
  // NOTE: Ensure this is containing 'v_ticket(RaffleId).cbor.cbor' as hex
  // @Sebastian I think this is the reason it's not working on main net
  const raffleIdEncoded = new Constr(0, [fromHex(raffleId)]);
  return {
    type: "PlutusV2",
    script: applyParamsToScript(V_TICKET_UNAPPLIED_HEX, raffleIdEncoded),
  };
}

export function getBuyTicketValidatorHex(raffleId: string): string {
  // This function returns the ticket validator script with the raffle id applied
  const raffleIdEncoded = new Constr(0, [fromHex(raffleId)]);
  return applyParamsToScript(V_TICKET_UNAPPLIED_HEX, raffleIdEncoded);
}

export function toEnvelope(tx: TxComplete | TxSigned) {
  return {
    type: "Tx BabbageEra",
    description: "",
    cborHex: tx.toString(),
  };
}
// export function getRaffleIdFromUTxO(utxo_containing_prize: string): string {
//   // const tx_hash = utxo_containing_prize.split("#")[0];
//   const utxo = new Constr(0, [fromHex(utxo_containing_prize)]);
//   const raffle_minting_policy = {
//     type: "PlutusV2",
//     script: applyParamsToScript(M_RAFFLE_CBOR_HEX, utxo),
//   };
//   return lucid.utils.mintingPolicyToId(raffle_minting_policy);
// }
