import { Card, CardContent, Stack, Typography } from "@mui/material";

import { Raffle } from "../types";
import moment from "moment";

interface RaffleRowProps {
  raffle: Raffle;
  onCardClick: (raffle: Raffle) => any;
  walletAddress: string;
}

const cardContentStyle: Record<string, string | number> = {
  position: "absolute",
  top: 0,
  left: 80,
  width: "95%",
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  backgroundColor: "#161c24",
};

export const RaffleRow = ({
  raffle,
  onCardClick,
  walletAddress,
}: RaffleRowProps) => {
  const timeRemaining = moment(raffle.end_date).fromNow();
  const myTickets: number[] = raffle.tickets_sold
    .filter((ticket) => ticket.return_address === walletAddress)
    .map((ticket) => ticket.number_of_tickets);

  const totalTicketsBought = myTickets.reduce(
    (partialSum, a) => partialSum + a,
    0
  );
  const totalAdaSpent =
    (myTickets.length * raffle.ticket_price_lovelace) / 1000000;
  const totalFluffSpent = totalTicketsBought * raffle.ticket_price_fluff;
  const winProbability = totalTicketsBought
    ? totalTicketsBought / raffle.number_of_tickets_sold
    : 0;
  const chanceOfWinningText = `${Math.round(winProbability * 10000) / 100}%`;
  const iWon = raffle.winning_address === walletAddress;
  return (
    <Card
      onClick={() => onCardClick(raffle)}
      raised
      sx={{
        borderRadius: 3,
        height: 80,
        width: "99%",
        margin: 1,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        border: "solid",
        borderWidth: iWon ? 2 : 0,
        borderColor: iWon ? "#FEC200" : "white",
        // opacity: 1,
        backgroundColor: "#161c24",
      }}
    >
      <Stack
        sx={{
          backgroundColor: "#161c24",
          // maxHeight: 80,
          width: 80,
          overflow: "hidden",
        }}
      >
        <img
          alt={raffle.prize.image_url}
          width="80"
          src={raffle.prize.image_url}
        />
      </Stack>
      <CardContent style={cardContentStyle}>
        <Stack direction="row" sx={{ flexGrow: 1, marginRight: 2 }}>
          <Stack direction="column" sx={{ width: 400 }}>
            <Typography fontSize={20}>{raffle.title}</Typography>
            <Typography fontSize={16}>
              {raffle.collection_name} · {raffle.category}
            </Typography>
          </Stack>

          <Stack
            // spacing={3}
            direction="row"
            justifyContent="space-between"
            sx={{ flexGrow: 1 }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Stack>
                <Typography fontSize={16}>
                  Raffle end{raffle.raffle_ended ? "ed" : "s"}:
                </Typography>
                <Typography fontSize={16} sx={{ textAlign: "center" }}>
                  {timeRemaining}
                </Typography>
              </Stack>
            </Stack>

            <Stack direction="row" spacing={1} alignItems="center">
              <Stack>
                <Typography fontSize={16}>Tickets bought:</Typography>
                <Typography fontSize={16} sx={{ textAlign: "center" }}>
                  {raffle.number_of_tickets_sold} | {totalTicketsBought}
                </Typography>
              </Stack>
            </Stack>

            <Stack direction="row" spacing={1} alignItems="center">
              <Stack>
                <Typography fontSize={16}>$fluff spent:</Typography>
                <Typography fontSize={16} sx={{ textAlign: "center" }}>
                  {/* {totalAdaSpent} | {totalFluffSpent} */}
                  {totalFluffSpent}
                </Typography>
              </Stack>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Stack>
                <Typography fontSize={16}>Chance of winning:</Typography>
                <Typography fontSize={16} sx={{ textAlign: "center" }}>
                  {chanceOfWinningText}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};
