import { getEnvVars } from "../constants";

interface ShowTxidProps {
  txid: string;
}
const { cardanoExplorerURL, cardanoExplorerName } = getEnvVars();

export function ShowTxid({ txid }: ShowTxidProps) {
  return (
    <a
      href={`${cardanoExplorerURL}/${txid}`}
      target="_some_other"
      style={{ color: "white" }}
    >
      show this transaction on {cardanoExplorerName}
    </a>
  );
}
