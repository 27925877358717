import { Alert, Button, Snackbar, TextField } from "@mui/material";

import { Raffle } from "../../types";
import { ShowTxid } from "../ShowTxid";
import { Stack } from "@mui/system";
import { WalletApi } from "@cardano-sdk/cip30";
import { buyTicketTransaction } from "../../transactions/buyTicketTransaction";
import { useState } from "react";
import { pickWinnerProcess } from "../../transactions/pickWinnerProcess";
import { collectTickets } from "../../transactions/collectTickets";

interface IBuyTicketProps {
  cardanoApi: WalletApi;
  raffle: Raffle;
}
const buttonStyle = {
  display: "flex",
  alignItems: "center",
};

export function CollectTicketsButton({ cardanoApi, raffle }: IBuyTicketProps) {
  const [txid, setTxid] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const transactionUrlAnchor = ShowTxid({ txid: txid || "" });

  return (
    <Stack
      direction="row"
      spacing={1}
      justifyContent="space-between"
      sx={{ flexGrow: 1 }}
    >
      <Button
        style={buttonStyle}
        onClick={async () => {
          try {
            const txid = await collectTickets(raffle, cardanoApi);
            setTxid(txid);
          } catch (e) {
            let message;
            if (e instanceof Error) message = e.message;
            else message = String(e);
            setErrorMessage(message);
          }
        }}
      >
        collect tickets
      </Button>
      <Snackbar
        open={!!txid}
        autoHideDuration={null}
        onClose={() => setTxid(null)}
      >
        <Alert
          onClose={() => setTxid(null)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Transaction submitted! {transactionUrlAnchor}
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={null}
        onClose={() => setErrorMessage(null)}
      >
        <Alert
          onClose={() => setErrorMessage(null)}
          severity="error"
          sx={{ width: "100%" }}
        >
          An error occurred! {errorMessage}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
