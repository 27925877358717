import { Icon, Stack } from "@mui/material";

import { Raffle } from "../../types";
import TwitterIcon from "@mui/icons-material/Twitter";

interface RaffleCardProps {
  raffle: Raffle;
}

export const Socials = ({ raffle }: RaffleCardProps) => {
  const socials = Object.entries(raffle.socials)
    .filter(([_social, url]) => {
      return !!url;
    })
    .map(([social, url]) => {
      switch (social) {
        case "twitter":
          return (
            <TwitterIcon
              style={{ cursor: "pointer", fontSize: 16 }}
              onClick={() => window.open(url)}
            />
          );
        case "discord":
          return (
            <Icon
              style={{ cursor: "pointer", fontSize: 16 }}
              onClick={() => window.open(url)}
            >
              discord
            </Icon>
          );
        case "jpgstore":
          return (
            <Icon
              style={{ cursor: "pointer", fontSize: 16 }}
              onClick={() => window.open(url)}
            >
              image
            </Icon>
          );
        case "website":
          return (
            <Icon
              style={{ cursor: "pointer", fontSize: 16 }}
              onClick={() => window.open(url)}
            >
              public
            </Icon>
          );
      }
    });
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {socials}
    </Stack>
  );
};
