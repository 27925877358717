import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import { WalletAndName } from "../types";
import { listWallets } from "../helpers/walletSelector";

interface WalletSelectorProps {
  setSelectedWallet: (walletAndName: WalletAndName) => void;
}
export function WalletSelector({ setSelectedWallet }: WalletSelectorProps) {
  const wallets = listWallets();

  return (
    <List>
      {wallets.map((wallet: WalletAndName) => {
        return (
          <ListItem key={wallet.name}>
            <ListItemButton onClick={() => setSelectedWallet(wallet)}>
              <ListItemIcon>
                <img
                  src={wallet.wallet.icon}
                  height="30"
                  alt={`wallet-icon-${wallet.name}`}
                />
              </ListItemIcon>
              <ListItemText primary={wallet.name} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
}
