import "../App.css";

import { Alert, Snackbar, Stack, Typography } from "@mui/material";

import { CreateRaffleForm } from "../components/CreateRaffleForm";
import { Footer } from "../components/Footer";
import { WalletApi } from "@cardano-sdk/cip30";
import { useState } from "react";
import { ShowTxid } from "../components/ShowTxid";
import { ChooseAsset } from "../components/ChooseAsset";
import { Asset } from "../apiRequests/assets";

type CreateRaffleProps = {
  api?: WalletApi;
};

export function CreateRaffle({ api }: CreateRaffleProps) {
  const [raffleMetaHash, setRaffleMetaHash] = useState("");
  const [txid, setTxid] = useState<string | null>(null);
  const transactionUrlAnchor = ShowTxid({ txid: txid || "" });

  const [selectedAsset, setSelectedAsset] = useState<Asset | undefined>();
  if (!api) {
    return (
      <Alert severity="info">Please connect your wallet to continue</Alert>
    );
  }
  return (
    <Stack sx={{ margin: 10 }}>
      {selectedAsset && (
        <CreateRaffleForm
          cardanoApi={api}
          {...{ setRaffleMetaHash, setTxid }}
          asset={selectedAsset}
        />
      )}
      <ChooseAsset
        cardanoApi={api}
        selectedAsset={selectedAsset}
        setSelectedAsset={setSelectedAsset}
      />

      {raffleMetaHash && (
        <Typography>Raffle meta created: {raffleMetaHash}</Typography>
      )}
      <Snackbar
        open={!!txid}
        autoHideDuration={null}
        onClose={() => setTxid(null)}
      >
        <Alert
          onClose={() => setTxid(null)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Transaction submitted! {transactionUrlAnchor}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
