import "./App.css";

import { Box, LinearProgress, Modal, Stack } from "@mui/material";
import { Cip30Wallet, WalletApi } from "@cardano-sdk/cip30";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useEffect, useState } from "react";

import { CreateRaffle } from "./routes/CreateRaffle";
import { Footer } from "./components/Footer";
import { HeaderBar } from "./components/HeaderBar";
import { ListRaffles } from "./routes/ListRaffles";
import { WalletSelector } from "./components/WalletSelector";
import { Raffle, WalletAndName } from "./types";
import { listWallets } from "./helpers/walletSelector";
import { CREATE_PATH, ORGANIZER_PATH } from "./constants";
import { getEnvVars } from "./constants";
import { getAssetInWallet, getWalletAddress } from "./apiRequests/assets";
import { setLucid } from "./transactions/common";
import { Profile } from "./routes/Profile";
import { fetchAllRaffles } from "./apiRequests/raffles";
const style = {
  position: "absolute" as "absolute",
  top: "0%",
  right: "0%",
  margin: 4,
  marginTop: 10,
  bgcolor: "background.paper",
  width: 280,
};
const { fluffPolicyId, fluffTokenName } = getEnvVars();

function App() {
  const [raffles, setRaffles] = useState<Raffle[] | undefined>();
  useEffect(() => {
    if (!raffles) {
      fetchAllRaffles().then((raffles) => {
        setRaffles(raffles);
      });
    }
  }, []);
  const [selectedWallet, setSelectedWallet] = useState<Cip30Wallet | undefined>(
    undefined
  );
  const [selectedWalletName, setSelectedWalletName] = useState<
    string | undefined
  >();
  const [loadingWallet, setLoadingWallet] = useState(false);
  const [fluffInWallet, setFluffInWallet] = useState<bigint>(BigInt(0));
  const [lovelaceInWallet, setLovelaceInWallet] = useState<bigint>(BigInt(0));
  const fluffAssetName = `${fluffPolicyId}${fluffTokenName}`;
  useEffect(() => {
    const previouslySelectedWalletName =
      window.localStorage.getItem("walletName");
    const wallets = listWallets();
    const previouslySelectedWallet = wallets.find(
      (wallet) => wallet.name === previouslySelectedWalletName
    );
    if (previouslySelectedWallet) {
      selectWallet(previouslySelectedWallet);
    } else {
      window.localStorage.removeItem("walletName");
    }
  }, [selectedWallet]);
  const selectWallet = (walletAndName: WalletAndName) => {
    window.localStorage.setItem("walletName", walletAndName.name);
    setSelectedWallet(walletAndName.wallet);
    setSelectedWalletName(walletAndName.name);
  };
  const [api, setApi] = useState<WalletApi | undefined>(undefined);
  const [modalOpen, setModalOpen] = useState(false);
  const [walletAddress, setWalletAddress] = useState<string>("");
  useEffect(() => {
    if (!api) return;
    getWalletAddress(api).then((address) => setWalletAddress(address));
  }, [api]);
  useEffect(() => {
    if (selectedWallet) {
      setLoadingWallet(true);
      selectedWallet
        .enable()
        .then((api) => {
          selectedWallet.isEnabled().then((x) => {
            x.valueOf() && setApi(api);
          });
          setLucid(api).then(() => {
            getAssetInWallet(api, fluffAssetName).then((quantity) => {
              setFluffInWallet(quantity);
              getAssetInWallet(api, "lovelace")
                .then((quantity) => setLovelaceInWallet(quantity))
                .finally(() => {
                  setLoadingWallet(false);
                });
            });
          });
        })
        .catch(() => {
          setSelectedWalletName(undefined);
          setLoadingWallet(false);
          setFluffInWallet(BigInt(0));
          setLovelaceInWallet(BigInt(0));
        });
    } else {
      setApi(undefined);
    }
  }, [selectedWallet]);

  return (
    <BrowserRouter>
      <Stack sx={{ height: 2 }}>{!raffles && <LinearProgress />}</Stack>
      <Stack sx={{ margin: 4 }}>
        <HeaderBar
          setConnectWalletModalOpen={setModalOpen}
          connectedWalletName={selectedWalletName}
          loadingWallet={loadingWallet}
          fluffInWallet={fluffInWallet}
          lovelaceInWallet={lovelaceInWallet}
        />
        <Modal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <WalletSelector
              setSelectedWallet={(wallet) => {
                selectWallet(wallet);
                setModalOpen(false);
              }}
            />
          </Box>
        </Modal>
        <Routes>
          <Route
            path="/:id"
            element={
              <ListRaffles
                raffles={raffles || []}
                api={api}
                organizerMode={false}
                setConnectWalletModalOpen={() => setModalOpen(true)}
                fluffInWallet={fluffInWallet}
                lovelaceInWallet={lovelaceInWallet}
                walletAddress={walletAddress}
              />
            }
          />
          <Route
            path="/"
            element={
              <ListRaffles
                raffles={raffles || []}
                api={api}
                organizerMode={false}
                setConnectWalletModalOpen={() => setModalOpen(true)}
                fluffInWallet={fluffInWallet}
                lovelaceInWallet={lovelaceInWallet}
                walletAddress={walletAddress}
              />
            }
          />
          <Route
            path={`/${CREATE_PATH}`}
            element={<CreateRaffle api={api} />}
          />
          <Route
            path={`/${ORGANIZER_PATH}`}
            element={
              <ListRaffles
                raffles={raffles || []}
                api={api}
                organizerMode={true}
                setConnectWalletModalOpen={() => setModalOpen(true)}
                fluffInWallet={fluffInWallet}
                lovelaceInWallet={lovelaceInWallet}
                walletAddress={walletAddress}
              />
            }
          />
          <Route
            path={`/${ORGANIZER_PATH}/:id`}
            element={
              <ListRaffles
                raffles={raffles || []}
                api={api}
                organizerMode={true}
                setConnectWalletModalOpen={() => setModalOpen(true)}
                fluffInWallet={fluffInWallet}
                lovelaceInWallet={lovelaceInWallet}
                walletAddress={walletAddress}
              />
            }
          />
          <Route
            path={`/profile`}
            element={
              <Profile
                raffles={raffles || []}
                api={api}
                setConnectWalletModalOpen={() => setModalOpen(true)}
                fluffInWallet={fluffInWallet}
                lovelaceInWallet={lovelaceInWallet}
                walletAddress={walletAddress}
                connectedWalletName={selectedWalletName}
              />
            }
          />
          <Route
            path={`/profile/:id`}
            element={
              <Profile
                raffles={raffles || []}
                api={api}
                setConnectWalletModalOpen={() => setModalOpen(true)}
                fluffInWallet={fluffInWallet}
                lovelaceInWallet={lovelaceInWallet}
                walletAddress={walletAddress}
                connectedWalletName={selectedWalletName}
              />
            }
          />
        </Routes>

        <Footer />
      </Stack>
    </BrowserRouter>
  );
}

export default App;
