import { Alert } from "@mui/material";

import { Raffle } from "../types";
import { ShowTxid } from "../components/ShowTxid";
import { Stack } from "@mui/system";
import { useState } from "react";

interface IBuyTicketProps {
  raffle: Raffle;
  walletAddress: string;
}

export function ShowWinningTransaction({
  raffle,
  walletAddress,
}: IBuyTicketProps) {
  const transactionUrlAnchor = ShowTxid({ txid: raffle.tx_out_ref || "" });
  if (raffle.winning_address === walletAddress)
    return (
      <Stack spacing={1}>
        <Alert severity="success" sx={{ width: "100%" }}>
          Congratulations! You won 🎉 <br />
          {transactionUrlAnchor}
        </Alert>
      </Stack>
    );
  return (
    <Stack spacing={1}>
      <Alert severity="info" sx={{ width: "100%", wordBreak: "break-all" }}>
        Winning address: {raffle.winning_address} <br />
        {transactionUrlAnchor}
      </Alert>
    </Stack>
  );
}
