import { Card, CardContent, CardMedia, Stack, Typography } from "@mui/material";

import { Asset } from "../apiRequests/assets";

interface RaffleCardProps {
  asset: Asset;
  onCardClick: () => void;
  isSelected: boolean;
}

export const AssetPreviewCard = ({
  asset,
  onCardClick,
  isSelected = false,
}: RaffleCardProps) => {
  const cardContentStyle: Record<string, string | number> = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    padding: 16,
    flexDirection: "column",
  };
  if (isSelected) {
    cardContentStyle.borderRadius = 3;
    cardContentStyle.border = "4px solid white ";
  }
  if (!asset.onchain_metadata) {
    return null;
  }
  let imageURL = "";
  try {
    imageURL = asset.onchain_metadata.image.replace(
      "ipfs://",
      "https://ipfs.io/ipfs/"
    );
  } catch (e) {
    console.log(
      "NFT does not contain asset.onchain_metadata.image",
      asset.onchain_metadata
    );
  }
  return (
    <Card
      onClick={onCardClick}
      raised
      sx={{ borderRadius: 3, width: 300, margin: 1 }}
      style={{ position: "relative" }}
    >
      <CardMedia
        component="img"
        alt={asset.onchain_metadata.image}
        height="400"
        image={imageURL}
        style={{ marginBottom: 0 }}
      />
      <CardContent className="hoverable-card" style={cardContentStyle}>
        <Stack
          justifyContent="space-between"
          direction="column"
          sx={{ height: "90%" }}
        >
          <Stack>
            <Typography fontSize={24} gutterBottom>
              {asset.onchain_metadata?.name}
            </Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};
