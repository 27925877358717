import { Button, CircularProgress, Stack } from "@mui/material";
import { getEnvVars } from "../constants";

import Logo from "../BearmarketLogo.png";
import { useLocation, useNavigate } from "react-router-dom";

type HeaderBarProps = {
  setConnectWalletModalOpen: (open: boolean) => void;
  fluffInWallet: bigint;
  lovelaceInWallet: bigint;
  connectedWalletName?: string;
  loadingWallet: boolean;
};

export const SHORT_NUMBER_FORMAT = Intl.NumberFormat("en-US", {
  notation: "compact",
  maximumFractionDigits: 1,
});
const { buyFluffLink } = getEnvVars();
const buttonStyle = {
  paddingLeft: 1,
  paddingRight: 1,
  width: 240,
};
const slimButtonStyle = {
  paddingLeft: 1,
  paddingRight: 1,
  width: 120,
};
export const HeaderBar = ({
  setConnectWalletModalOpen,
  connectedWalletName,
  fluffInWallet,
  lovelaceInWallet,
  loadingWallet,
}: HeaderBarProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isProfilePage = location.pathname === "/profile";
  const buttonText = connectedWalletName
    ? `${connectedWalletName}: ${SHORT_NUMBER_FORMAT.format(
        fluffInWallet
      )} $fluff ${SHORT_NUMBER_FORMAT.format(
        lovelaceInWallet / BigInt(1000000)
      )} ADA`
    : "Connect Wallet";
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{ marginBottom: 2 }}
    >
      <a href="https://bearmarket.io/">
        <img height="36" src={Logo} alt="BearmarketLogo" />
      </a>

      <Stack direction="row">
        {connectedWalletName && !loadingWallet && (
          <Button
            onClick={() => navigate(isProfilePage ? "/" : "/profile")}
            sx={slimButtonStyle}
          >
            {isProfilePage ? "Raffles" : "Profile"}
          </Button>
        )}
        <Button target="_blank" href={buyFluffLink} sx={slimButtonStyle}>
          Buy $fluff
        </Button>
        <Button
          onClick={() => setConnectWalletModalOpen(true)}
          sx={buttonStyle}
        >
          {loadingWallet ? <CircularProgress size={20} /> : buttonText}
        </Button>
      </Stack>
    </Stack>
  );
};
