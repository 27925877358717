import { Icon, Stack, Typography } from "@mui/material";

import { Raffle } from "../../types";

interface RaffleCardProps {
  raffle: Raffle;
  size?: "s" | "m";
}

export const Price = ({ raffle, size = "m" }: RaffleCardProps) => {
  const fontSize = size === "m" ? 14 : 12;
  const iconFontSize = size === "m" ? 20 : 16;

  const pricePrefixText = size === "m" ? "Price:" : "";
  const priceText = raffle.is_ada_only
    ? `${pricePrefixText} ${raffle.ticket_price_lovelace / 1000000} ADA`
    : `${pricePrefixText} ${raffle.ticket_price_fluff} $fluff`;
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Icon style={{ fontSize: iconFontSize }}>sell</Icon>
      <Typography fontSize={fontSize}>{priceText}</Typography>
    </Stack>
  );
};
