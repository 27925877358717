import * as React from "react";
import { useEffect, useState } from "react";
import { Grid, Stack } from "@mui/material";

import Typography from "@mui/material/Typography";
import { WalletApi } from "@cardano-sdk/cip30";
import { Asset, listAllAssetsInWallet } from "../apiRequests/assets";
import { AssetPreviewCard } from "./AssetPreviewCard";

type ChooseAssetProps = {
  cardanoApi: WalletApi;
  selectedAsset?: Asset;
  setSelectedAsset: (asset: Asset) => void;
};

function nowForLog() {
  return new Date().toLocaleTimeString();
}

export const ChooseAsset = ({
  cardanoApi,
  selectedAsset,
  setSelectedAsset,
}: ChooseAssetProps) => {
  const [assets, setAssets] = useState<Asset[]>([]);

  useEffect(() => {
    console.log("fetching assets");
    listAllAssetsInWallet(cardanoApi).then((assets) => {
      console.log(assets);
      setAssets(assets);
    });
  }, []);
  return (
    <Stack spacing={1}>
      <Typography fontSize={16}>Choose an NFT to raffle:</Typography>
      <Grid container spacing={3} justifyContent="start" alignItems="center">
        {assets.map((asset) => (
          <Grid key={asset.asset} item>
            <AssetPreviewCard
              asset={asset}
              onCardClick={() => setSelectedAsset(asset)}
              isSelected={selectedAsset?.asset === asset.asset}
            />
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};
