import { Raffle } from "../types";

export const getTotalTicketsBought = (
  raffle: Raffle,
  walletAddress: string
): number => {
  return raffle.tickets_sold
    .filter((ticket) => ticket.return_address === walletAddress)
    .map((ticket) => ticket.number_of_tickets)
    .reduce((partialSum, a) => partialSum + a, 0);
};
