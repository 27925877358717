import { Icon, Stack, Typography } from "@mui/material";

import { Raffle } from "../../types";
import moment from "moment";

interface RaffleCardProps {
  raffle: Raffle;
  expand?: boolean;
  size?: "s" | "m";
}
const options: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
};

export const EndDate = ({
  raffle,
  expand = false,
  size = "m",
}: RaffleCardProps) => {
  const timeRemaining = moment(raffle.end_date).fromNow();
  const endDate = new Date(raffle.end_date);
  const fontSize = size === "m" ? 14 : 12;
  const iconFontSize = size === "m" ? 20 : 16;
  const labelText = raffle.is_active && !raffle.raffle_ended ? "Ends" : "Ended";

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {expand ? (
        <Typography fontSize={fontSize}>
          {labelText}: {endDate.toLocaleTimeString("en", options)}
        </Typography>
      ) : (
        <Typography fontSize={fontSize}>{timeRemaining}</Typography>
      )}
      <Icon style={{ fontSize: iconFontSize }}>schedule</Icon>
    </Stack>
  );
};
