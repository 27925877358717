import {
  CircularProgress,
  Grid,
  LinearProgress,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

import { FilterBar, filterRaffles } from "../components/FilterBar";
import { Raffle, RaffleTicket } from "../types";
import { RaffleDetailView } from "../components/RaffleDetailView";
import { WalletApi } from "@cardano-sdk/cip30";
import { fetchActiveRaffles } from "../apiRequests/raffles";
import { Link, useNavigate, useParams } from "react-router-dom";
import { RaffleRow } from "../components/RaffleRow";
import { StatsCard } from "../components/StatsCard";
import { getTotalTicketsBought } from "../helpers/totalTickets";
import { SHORT_NUMBER_FORMAT } from "../components/HeaderBar";

type ProfileProps = {
  raffles: Raffle[];
  api?: WalletApi;
  setConnectWalletModalOpen: () => void;
  fluffInWallet: bigint;
  lovelaceInWallet: bigint;
  walletAddress: string;
  connectedWalletName?: string;
};

export function Profile({
  raffles,
  api,
  setConnectWalletModalOpen,
  fluffInWallet,
  lovelaceInWallet,
  walletAddress,
  connectedWalletName,
}: ProfileProps) {
  let { id } = useParams();
  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedRaffle, setSelectedRaffle] = useState<Raffle | undefined>();
  const [filteredRaffles, setFilteredRaffles] = useState<Raffle[]>([]);
  const myRaffles = raffles
    .filter((raffle: Raffle) => {
      return new Date(raffle.start_date) < new Date();
    })
    .filter((raffle) =>
      raffle.tickets_sold.some(
        (ticket) => ticket.return_address === walletAddress
      )
    );
  useEffect(() => {
    setFilteredRaffles(
      filterRaffles(myRaffles, "All", "All", "", "Ending soon", "")
    );
    const linkedRaffle = myRaffles.find((raffle) => raffle.id === id);
    if (linkedRaffle) {
      setSelectedRaffle(linkedRaffle);
      setModalOpen(true);
    }
  }, [raffles, walletAddress]);

  const rafflesWon = raffles.filter(
    (raffle) => raffle.winning_address === walletAddress
  );

  const totalTicketsBought = raffles
    .map((raffle) => getTotalTicketsBought(raffle, walletAddress))
    .reduce((partialSum, num) => partialSum + num, 0);

  let totalAdaSpent = 0;
  let totalFluffSpent = 0;

  raffles.forEach((raffle: Raffle) => {
    const myTickets: RaffleTicket[] = raffle.tickets_sold.filter(
      (ticket) => ticket.return_address === walletAddress
    );

    totalAdaSpent +=
      (myTickets.length * raffle.ticket_price_lovelace) / 1000000;
    totalFluffSpent += totalTicketsBought * raffle.ticket_price_fluff;
  });

  const totalFloorPrice =
    rafflesWon
      .map((raffle) => raffle.floor_price_lovelace)
      .reduce((partialSum, num) => partialSum + num, 0) / 1000000;

  if (!connectedWalletName)
    return (
      <Stack spacing={8}>
        <FilterBar
          raffles={myRaffles}
          setFilteredRaffles={setFilteredRaffles}
          walletAddress={walletAddress}
          initialStatus={"All"}
        />
        <Typography sx={{ textAlign: "center", fontSize: 20 }}>
          Please connect your wallet to see your raffle entries.
        </Typography>
      </Stack>
    );
  const userHasNotTakenPart = !totalTicketsBought;
  if (userHasNotTakenPart)
    return (
      <Stack spacing={8}>
        <FilterBar
          raffles={myRaffles}
          setFilteredRaffles={setFilteredRaffles}
          walletAddress={walletAddress}
          initialStatus={"All"}
        />

        <Typography sx={{ textAlign: "center", fontSize: 20 }}>
          You have not taken part in any raffles. Please buy raffle tickets on{" "}
          <a
            style={{ color: "#FEC200", cursor: "pointer" }}
            onClick={() => navigate("/")}
          >
            Raffles
          </a>{" "}
          to see your attended raffles.
        </Typography>
      </Stack>
    );

  return (
    <Stack>
      {/* <Stack
        direction={"row"}
        sx={{ height: 160 }}
        justifyContent="space-evenly"
      >
        <StatsCard title={raffles.length} subtitle="Total raffles" />
        <StatsCard title={totalTicketsBought} subtitle="Total tickets bought" />
        <StatsCard
          title={`${SHORT_NUMBER_FORMAT.format(totalAdaSpent)}`}
          subtitle="Total ADA spent"
        />
        <StatsCard
          title={`${SHORT_NUMBER_FORMAT.format(totalFluffSpent)}`}
          subtitle="Total fluff"
        />
        <StatsCard title={rafflesWon.length} subtitle="Total raffles won" />
        <StatsCard
          title={totalFloorPrice / 1000000}
          subtitle="Total value of NFTs"
        />
      </Stack> */}
      <FilterBar
        raffles={myRaffles}
        setFilteredRaffles={setFilteredRaffles}
        walletAddress={walletAddress}
        initialStatus="All"
      />
      {filteredRaffles.map((raffle, index) => (
        <Grid key={index} item>
          <Stack>
            <Link to={raffle.id} replace={true}>
              <RaffleRow
                onCardClick={() => {
                  setSelectedRaffle(raffle);
                  setModalOpen(true);
                }}
                raffle={raffle}
                walletAddress={walletAddress}
              />
            </Link>
          </Stack>
        </Grid>
      ))}

      <Modal
        open={modalOpen}
        onClose={() => {
          navigate("/profile");
          setModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          margin: "auto",
          alignSelf: "center",
          justifyContent: "center",
          backgroundColor: "rgba(0, 0, 0, 0.6)",
        }}
      >
        <RaffleDetailView
          api={api}
          raffle={selectedRaffle}
          setConnectWalletModalOpen={setConnectWalletModalOpen}
          organizerMode={false}
          lovelaceInWallet={lovelaceInWallet}
          fluffInWallet={fluffInWallet}
          walletAddress={walletAddress}
        />
      </Modal>
    </Stack>
  );
}
