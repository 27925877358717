import { Alert, Button, Snackbar, TextField, Typography } from "@mui/material";

import { Raffle } from "../../types";
import { ShowTxid } from "../ShowTxid";
import { Stack } from "@mui/system";
import { WalletApi } from "@cardano-sdk/cip30";
import { buyTicketTransaction } from "../../transactions/buyTicketTransaction";
import { useState } from "react";

interface IBuyTicketProps {
  cardanoApi: WalletApi;
  raffle: Raffle;
  fluffInWallet: bigint;
  lovelaceInWallet: bigint;
}

export function BuyTicketButton({
  cardanoApi,
  raffle,
  fluffInWallet,
  lovelaceInWallet,
}: IBuyTicketProps) {
  const [numberOfTickets, setNumberOfTicket] = useState(1);
  const [txid, setTxid] = useState<string | null>(null);
  const transactionUrlAnchor = ShowTxid({ txid: txid || "" });
  const [showSnack, setShowSnack] = useState(false);
  let buttonText = "Buy tickets";
  const winProbability = numberOfTickets
    ? numberOfTickets / (numberOfTickets + raffle.number_of_tickets_sold)
    : 0;
  const chanceOfWinningText = `Chance of winning approx. ${
    Math.round(winProbability * 10000) / 100
  }%`;

  const totalFluffPrice = raffle.is_ada_only
    ? `Total: ${(raffle.ticket_price_lovelace * numberOfTickets) / 1000000} ADA`
    : `Total: ${raffle.ticket_price_fluff * numberOfTickets} $fluff`;

  const totalLovelace = raffle.is_ada_only
    ? raffle.ticket_price_lovelace * numberOfTickets
    : raffle.ticket_price_lovelace;
  if (lovelaceInWallet < totalLovelace) buttonText = "Not enough ADA";
  if (fluffInWallet < raffle.ticket_price_fluff * numberOfTickets)
    buttonText = "Not enough $fluff";
  let maxNumTickets;
  if (raffle.ticket_price_lovelace === 0)
    buttonText = "Cannot have 0 lovelace in utxo";
  else if (raffle.ticket_price_fluff === 0 && !raffle.is_ada_only)
    buttonText = "Fluff raffle ticket cannot cost 0 fluff";
  else {
    maxNumTickets = raffle.is_ada_only
      ? lovelaceInWallet / BigInt(raffle.ticket_price_lovelace)
      : fluffInWallet / BigInt(raffle.ticket_price_fluff);
  }

  return (
    <Stack>
      <Stack
        direction="row"
        spacing={1}
        justifyContent="space-between"
        sx={{ flexGrow: 1 }}
      >
        <TextField
          label="Number of tickets"
          value={numberOfTickets}
          InputProps={{
            inputProps: {
              min: 0,
              max: maxNumTickets,
            },
          }}
          onChange={(e) => {
            setNumberOfTicket(parseInt(e.target.value) || 0);
          }}
          type="number"
          sx={{ width: 120 }}
        />
        <Button
          variant="contained"
          sx={{ flexGrow: 1 }}
          onClick={async () => {
            const txid = await buyTicketTransaction(
              cardanoApi,
              raffle,
              numberOfTickets
            );
            setTxid(txid);
            setShowSnack(true);
          }}
          disabled={
            !numberOfTickets ||
            !raffle.is_active ||
            raffle.raffle_ended ||
            buttonText !== "Buy tickets"
          }
        >
          {buttonText}
        </Button>
        <Snackbar
          open={!!showSnack}
          autoHideDuration={null}
          onClose={() => setShowSnack(false)}
        >
          <Alert
            onClose={() => setShowSnack(false)}
            severity="success"
            sx={{ width: "100%" }}
          >
            Transaction submitted! {transactionUrlAnchor}
          </Alert>
        </Snackbar>
      </Stack>
      <Stack
        direction="row"
        spacing={1}
        justifyContent="space-between"
        sx={{ marginTop: 1 }}
      >
        <Typography fontSize={14}>{totalFluffPrice}</Typography>
        <Typography fontSize={14}>{chanceOfWinningText}</Typography>
      </Stack>
    </Stack>
  );
}
