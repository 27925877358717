import { Alert, Box, Button, Typography } from "@mui/material";

import { BuyTicketButton } from "./Buttons/BuyTicketButton";
import { EndDate } from "./RaffleCardInfo/EndDate";
import { Price } from "./RaffleCardInfo/Price";
import { Raffle } from "../types";
import { Socials } from "./RaffleCardInfo/Socials";
import { Stack } from "@mui/system";
import { TicketsSold } from "./RaffleCardInfo/TicketsSold";
import { WalletApi } from "@cardano-sdk/cip30";
import { pickWinnerProcess } from "../transactions/pickWinnerProcess";
import { collectTickets } from "../transactions/collectTickets";
import { FloorPrice } from "./RaffleCardInfo/FloorPrice";
import { ShowWinningTransaction } from "./ShowWinningTransaction";
import { PickWinnerButton } from "./Buttons/PickWinnerButton";
import { CollectTicketsButton } from "./Buttons/CollectTicketsButton";

interface IRaffleDetailViewProps {
  raffle?: Raffle;
  api?: WalletApi;
  setConnectWalletModalOpen: () => void;
  organizerMode: boolean;
  fluffInWallet: bigint;
  lovelaceInWallet: bigint;
  walletAddress: string;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  boxShadow: 24,
};

const buttonStyle = {
  display: "flex",
  alignItems: "center",
};

export function RaffleDetailView({
  raffle,
  api,
  setConnectWalletModalOpen,
  organizerMode,
  fluffInWallet,
  lovelaceInWallet,
  walletAddress,
}: IRaffleDetailViewProps) {
  if (!raffle)
    return (
      <Box>
        <Typography sx={{ flex: 0 }}>
          Something went wrong, no raffle selected 🤔
        </Typography>
      </Box>
    );

  return (
    <Box alignItems="center" justifyContent="flex-end" sx={style}>
      <Stack
        direction="row"
        sx={{
          backgroundColor: "#161c24",
          borderRadius: 3,
          overflow: "hidden",
        }}
        justifyContent="space-between"
      >
        <Stack sx={{ overflow: "hidden" }}>
          <img
            alt={raffle?.title}
            src={raffle.prize.image_url}
            style={{ minWidth: 420, minHeight: 420 }}
          />
        </Stack>

        <Stack
          justifyContent="space-between"
          sx={{
            padding: 1,
            flexGrow: 1,
            maxWidth: 500,
            margin: 2,
          }}
        >
          <Stack spacing={2}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="subtitle2">
                {raffle.collection_name} · {raffle.category}
              </Typography>
              <Socials raffle={raffle} />
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ flexGrow: 1 }}
            >
              <Typography variant="h4" noWrap sx={{ textOverflow: "ellipsis" }}>
                {raffle.title}
              </Typography>
            </Stack>

            <Typography>{raffle?.description}</Typography>
          </Stack>

          <Stack spacing={2}>
            {!raffle.is_ada_only && (
              <Typography fontSize={10}>
                * a mininimum of {raffle.ticket_price_lovelace / 1000000} ADA is
                included in all transactions
              </Typography>
            )}
            <Stack direction="row" justifyContent="space-between">
              <Price raffle={raffle} />

              <TicketsSold raffle={raffle} walletAddress={walletAddress} />
            </Stack>

            <Stack direction="row" justifyContent="space-between">
              <FloorPrice raffle={raffle} />
              <EndDate raffle={raffle} expand />
            </Stack>
            {!raffle.is_active ? (
              <ShowWinningTransaction
                raffle={raffle}
                walletAddress={walletAddress}
              />
            ) : api && raffle ? (
              <BuyTicketButton
                cardanoApi={api}
                raffle={raffle}
                fluffInWallet={fluffInWallet}
                lovelaceInWallet={lovelaceInWallet}
              />
            ) : (
              <Button style={buttonStyle} onClick={setConnectWalletModalOpen}>
                Connect Wallet
              </Button>
            )}
            {api && raffle && organizerMode && (
              <Stack>
                <PickWinnerButton cardanoApi={api} raffle={raffle} />
                <CollectTicketsButton cardanoApi={api} raffle={raffle} />
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}
