import { WalletApi } from "@cardano-sdk/cip30";
import { Constr, Data } from "lucid-cardano";

import { Buffer } from "buffer";
import { Raffle } from "../types";
import { getLucid, getTicketValidator, toEnvelope } from "./common";
import { getEnvVars } from "../constants";

export async function buyTicketTransaction(
  cardanoApi: WalletApi,
  raffle: Raffle,
  numberOfTickets: number
): Promise<string> {
  console.log("buyTicket", raffle);
  const lucid = await getLucid(cardanoApi);

  const ticketScript = getTicketValidator(raffle.id);
  const ticketScriptAddress = lucid.utils.validatorToAddress(ticketScript);
  console.log("ticketScriptAddress", ticketScriptAddress);

  const ourAddress = await lucid.wallet.address();
  console.log("ourAddress", ourAddress);
  // FIXME: This stores the bech32 string as datum. This will not work for accessing it from plutus.
  const ticketDatum = Data.to(new Constr(0, [new Buffer(ourAddress, "ascii")]));

  const { fluffPolicyId, fluffTokenName } = getEnvVars();

  const ticketValue = raffle.is_ada_only
    ? {
        lovelace: BigInt(raffle.ticket_price_lovelace * numberOfTickets),
      }
    : {
        lovelace: BigInt(raffle.ticket_price_lovelace),
        [fluffPolicyId + fluffTokenName]: BigInt(
          raffle.ticket_price_fluff * numberOfTickets
        ),
      };
  let txDraft = lucid.newTx();
  txDraft = txDraft.payToContract(
    ticketScriptAddress,
    { inline: ticketDatum },
    ticketValue
  );

  const tx = await txDraft.complete();
  console.log("tx", toEnvelope(tx));

  const signedTx = await tx.sign().complete();
  console.log("signedTx", toEnvelope(signedTx));

  const txid = await signedTx.submit();
  console.log({ txid });
  return txid;
}
