import { Constr, Data, Script } from "lucid-cardano";

import { Raffle } from "../types";

import { WalletApi } from "@cardano-sdk/cip30";
import { getLucid, getTicketValidator, toEnvelope } from "./common";

// Collects all outputs still owned by the raffle's ticket validator in one or
// more transactions.
export async function collectTickets(raffle: Raffle, cardanoApi: WalletApi) {
  console.log("collectTickets", raffle);
  const lucid = await getLucid(cardanoApi);

  const ourAddress = await lucid.wallet.address();
  console.log("ourAddress", ourAddress);

  const ticketValidator: Script = {
    type: "PlutusV2",
    script: raffle.buy_ticket_cbor_hex,
  };
  // const ticketAddress = lucid.utils.validatorToAddress(ticketScript);
  console.log("ticketAddress", raffle.buy_ticket_address);

  // Querying ticket UTxOs from blockfrost
  const ticketUtxos = await lucid.utxosAt(raffle.buy_ticket_address);
  console.log("ticketUtxos", ticketUtxos);

  // NOTE: This corresponds to 'Collect' in /validators/src/TicketValidator.hs
  const redeemer = Data.to(new Constr(0, []));

  // TODO: Determine whether multiple transactions are required
  if (!ticketUtxos.length) {
    throw new Error(
      `There are no more tickets remaining to collect for this raffle`
    );
  }

  try {
    const utxo_subset = ticketUtxos.slice(0, 8);
    console.log("utxo_subset.length", utxo_subset.length);
    const tx = await lucid
      .newTx()
      .collectFrom(utxo_subset, redeemer)
      .attachSpendingValidator(ticketValidator)
      .addSigner(ourAddress)
      .complete();
    console.log("tx", toEnvelope(tx));

    const signedTx = await tx.sign().complete();
    console.log("signedTx", toEnvelope(signedTx));

    const txid = await signedTx.submit();
    console.log({ txid });
    return txid;
  } catch (e) {
    console.error(e);
    throw new Error(`Could not collect tickets, ${e}`);
  }
}
