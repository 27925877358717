import * as React from "react";

import dayjs, { Dayjs } from "dayjs";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

type MyDateTimePickerProps = {
  label: string;
  dateTime: string;
  setDateTime: (dateTime: Dayjs | null) => void;
};
export const MyDateTimePicker = ({
  dateTime,
  setDateTime,
  label,
}: MyDateTimePickerProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack spacing={3}>
        <DateTimePicker
          label={label}
          value={dateTime}
          onChange={(newValue: Dayjs | null) => {
            setDateTime(newValue);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </Stack>
    </LocalizationProvider>
  );
};
