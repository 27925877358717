import {
  AppBar,
  FormControl,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";

import { Raffle } from "../types";
import { Stack } from "@mui/system";

type FilterBarProps = {
  raffles: Raffle[];
  setFilteredRaffles: (raffles: Raffle[]) => void;
  walletAddress: string;
  initialStatus?: raffleStatus;
};
type raffleStatus = "Active" | "Ended" | "All";
type sortBy =
  | "Cheapest first"
  | "Expensive first"
  | "Most popular"
  | "Least popular"
  | "Ending soon";

const getSearchableFields = (raffle: Raffle): string[] => {
  return [
    raffle.category,
    raffle.description,
    raffle.title,
    raffle.category,
    raffle.collection_name,
  ];
};

export const filterRaffles = (
  raffles: Raffle[],
  status: raffleStatus,
  category: string,
  searchTerm: string,
  sortBy: sortBy,
  walletAddress: string
): Raffle[] => {
  return raffles
    .filter((raffle) => {
      if (!searchTerm) return true;
      return getSearchableFields(raffle).some((value) => {
        return (
          value &&
          value
            .toString()
            .toLowerCase()
            .includes(searchTerm.toLowerCase().trim())
        );
      });
    })
    .filter((raffle) => {
      if (status === "All") return true;
      if (status === "Active") return raffle.is_active && !raffle.raffle_ended;
      if (status === "Ended") return !raffle.is_active || raffle.raffle_ended;
    })
    .filter((raffle) => {
      if (category === "All") return true;
      return category === raffle.category;
    })
    .sort((a: Raffle, b: Raffle) => {
      if (sortBy === "Cheapest first")
        return a.ticket_price_lovelace - b.ticket_price_lovelace;
      if (sortBy === "Expensive first")
        return b.ticket_price_lovelace - a.ticket_price_lovelace;
      if (sortBy === "Most popular")
        return b.number_of_tickets_sold - a.number_of_tickets_sold;
      if (sortBy === "Least popular")
        return a.number_of_tickets_sold - b.number_of_tickets_sold;
      if (sortBy === "Ending soon") {
        if (!a.raffle_ended && !b.raffle_ended)
          return Date.parse(a.end_date) - Date.parse(b.end_date);
        return Date.parse(b.end_date) - Date.parse(a.end_date);
      }
      return 0;
    });
};

export const FilterBar = ({
  raffles,
  setFilteredRaffles,
  walletAddress,
  initialStatus,
}: FilterBarProps) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [category, setCategory] = useState<string>("All");

  const allCategories = raffles
    .map((raffle) => raffle.category)
    .filter((category) => !!category)
    .reduce(
      (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
      ["All"] as string[]
    );

  const [status, setStatus] = useState<raffleStatus>(initialStatus || "Active");
  useEffect(() => {
    initialStatus !== status && setStatus(initialStatus || "Active");
  }, [initialStatus]);
  const allStatuses: raffleStatus[] = ["Active", "Ended", "All"];

  const [sortBy, setSortBy] = useState<sortBy>("Ending soon");

  const allSortOptions: sortBy[] = [
    "Most popular",
    "Least popular",
    "Cheapest first",
    "Expensive first",
    "Ending soon",
  ];
  useEffect(() => {
    setFilteredRaffles(
      filterRaffles(
        raffles,
        status,
        category,
        searchTerm,
        sortBy,
        walletAddress
      )
    );
  }, [status, category, searchTerm, sortBy]);

  return (
    <Stack
      direction={"row"}
      sx={{
        marginBottom: 4,
        marginTop: 1,
        flexGrow: 1,
      }}
      spacing={1}
    >
      <Stack direction={"row"} sx={{ flexGrow: 1 }}>
        <TextField
          label="Search"
          value={searchTerm}
          onChange={(event) => setSearchTerm(event.target.value)}
          sx={{ flexGrow: 1, minWidth: 200 }}
        ></TextField>
      </Stack>
      <Stack direction={"row"} alignSelf={"end"} spacing={1}>
        <FormControl sx={{ width: 200 }}>
          <InputLabel>Status</InputLabel>
          <Select
            value={status}
            label="Status"
            onChange={(event) => setStatus(event.target.value as raffleStatus)}
          >
            {allStatuses.map((raffleStatus) => {
              return (
                <MenuItem value={raffleStatus} key={raffleStatus}>
                  {raffleStatus}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl sx={{ width: 200 }}>
          <InputLabel>Category</InputLabel>
          <Select
            value={category}
            label="Category"
            onChange={(event) => setCategory(event.target.value)}
          >
            {allCategories.map((category) => {
              return (
                <MenuItem value={category} key={category}>
                  {category}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <FormControl sx={{ width: 200 }}>
          <InputLabel>Sort By</InputLabel>
          <Select
            value={sortBy}
            label="Sort By"
            onChange={(event) => setSortBy(event.target.value as sortBy)}
          >
            {allSortOptions.map((price) => {
              return (
                <MenuItem value={price} key={price}>
                  {price}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Stack>
    </Stack>
  );
};
